<template>
  <div class="booking-request px-4">
    <div class="text-center">
      <img
        @click="$router.push('/')"
        class="cursor-pointer mx-auto"
        :src="require('@/assets/images/global/Logo.png')"
      />
      <span class="font-20 d-block font-500 black--text mt-3"
        >New booking request</span
      >
    </div>
    <div class="booking-request__line"></div>

    <div class="d-flex align-center">
      <img
        class="user-avatar mr-3"
        v-if="obj.user && obj.user.picture"
        :src="obj.user.picture.url"
      />
      <span class="d-inline-block ml-2 font-13 white-grey--text">{{
        obj.user.name
      }}</span>
    </div>

    <div class="booking-request__line"></div>

    <div class="d-flex align-center">
      <span class="font-14 black-grey--text d-inline-block mr-1">
        Experience name:
      </span>
      <span class="font-14 error--text" v-if="obj.activity">
        {{ obj.activity.title }}</span
      >
      <span class="font-14 error--text" v-if="!obj.activity"> No </span>
    </div>
    <div class="booking-request__line"></div>

    <div class="d-flex align-center">
      <span class="font-14 black-grey--text d-inline-block mr-1">
        Experience date:
      </span>
      <span v-if="obj.date" class="font-14 error--text">
        {{ obj.date.slice(0, 10) }}</span
      >
    </div>
    <div class="booking-request__line"></div>

    <div class="d-flex align-center">
      <span class="font-14 black-grey--text d-inline-block mr-1"> PEOPLE </span>
      <span class="font-14 error--text"> {{ obj.numberOfPeople }}</span>
    </div>
    <div class="booking-request__line"></div>

    <div class="d-flex align-center">
      <span class="font-14 black-grey--text d-inline-block mr-1"> Pets </span>
      <span class="font-14 error--text"> {{ obj.pets }}</span>
    </div>

    <div class="booking-request__line"></div>

    <div
      class="blue py-1 px-3 radius-5 d-inline-block mr-3"
      v-for="(option, i) in obj.options"
      :key="i"
    >
      <span class="font-14 white--text"> option {{ i }}</span>
    </div>
    <div class="booking-request__line"></div>

    <div class="d-flex align-center">
      <span class="font-14 black-grey--text d-inline-block mr-1">
        Payment Method:
      </span>
      <span class="font-14 black--text"> Cash on confirmation</span>
    </div>
    <div class="booking-request__line"></div>

    <div class="d-flex align-center">
      <span class="font-14 black-grey--text d-block"> Total payment: </span>
      <span class="font-14 error--text"> ${{ obj.amount }}</span>
    </div>

    <div>
      <v-btn
        class="mt-3"
        color="secondary"
        elevation="0"
        rounded
        block
        height="40px"
        @click="acceptHandler"
      >
        <span class="white--text font-14">Accept booking</span>
      </v-btn>

      <v-btn
        class="mt-4"
        color="lightblue"
        outlined
        elevation="0"
        rounded
        block
        height="40px"
        @click="rejectHandler"
      >
        <span class="lightblue--text font-14">Reject booking</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    avatar: require("@/assets/images/global/avatar.png"),
    obj: { user: { picture: {} }, activity: {} },
  }),
  methods: {
    async getData() {
      let code = this.$route.params.id;
      let { data } = await this.$axios.get("booking-request/" + code);
      if (data.success) {
        this.obj = data.booking;
      }
    },
    async acceptHandler() {
      let { data } = await this.$axios.post("booking-request/accept-reject", {
        code: this.$route.params.id,
        isAccepted: true,
      });
      if (data) {
        this.$store.dispatch("showSnack", {
          text: "Booking Accepted Successfully",
          color: "success",
        });
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
    async rejectHandler() {
      let { data } = await this.$axios.post("booking-request/accept-reject", {
        code: this.$route.params.id,
        isAccepted: false,
      });
      if (data) {
        this.$store.dispatch("showSnack", {
          text: "Booking Accepted Successfully",
          color: "success",
        });
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.booking-request {
  width: 400px;
  margin: auto;

  &__line {
    width: 100%;
    height: 1px;
    background: #eaedf3;
    margin: 10px 0px;
  }
}
</style>
